import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import { flexCenter } from 'style/global.styles';

export const root = css`
  max-width: ${spacing(528)};
  display: flex;
  align-items: flex-start;
`;

export const check = css`
  width: ${spacing(48)};
  height: ${spacing(48)};
  min-width: ${spacing(48)};
  border-radius: 50%;
  border: 1.5px solid hsl(var(--color-borderBlack-2));
  margin-right: ${spacing(40)};
  ${flexCenter};

  svg {
    width: 10px;
    height: 7.5px;
  }
`;

export const content = css`
  ${font.size.medium};
  margin-top: ${spacing(20)};
`;
