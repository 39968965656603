import Seo from 'modules/app/components/Seo';
import React from 'react';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';
import { RequestDemo } from 'modules/requestDemo';

const RequestDemoPage: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Request a Demo - Roango"
        description="Whether you wish to learn more details about how Roango helps companies find the right talent or you need a custom plan, our team is here to help. Reach out and we'll go over what Roango can do for you, without any commitment."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <RequestDemo />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default RequestDemoPage;
