import { Heading } from 'modules/app';
import React from 'react';

import Check from 'assets/icons/checkDark.svg';
import * as styles from '../styles/Success.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';

export const Success: React.FC = () => {
  return (
    <section css={styles.root}>
      <div css={styles.check}>
        <Check />
      </div>
      <div>
        <Heading as="h3" size="fluidXLarge">
          <FormattedMessage id="requestDemo.success.title" />
        </Heading>
        <p css={styles.content}>
          <FormattedMessage id="requestDemo.success.content" />
        </p>
      </div>
    </section>
  );
};
