import { FormattedMessage } from 'gatsby-plugin-intl';
import { Footer, Heading, MainContent, Navbar } from 'modules/app';
import React, { useState } from 'react';
import { DemoForm, Success } from './components';

import * as styles from './styles/RequestDemo.styles';

export const RequestDemo: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  function handleSuccess() {
    setIsSuccess(true);
  }

  return (
    <>
      <Navbar />
      <MainContent>
        <section css={styles.root}>
          <div css={styles.content}>
            <Heading as="h1" size="fluidHuge">
              <FormattedMessage id="requestDemo.title" />
            </Heading>
            <p>
              <FormattedMessage id="requestDemo.content" />
            </p>
          </div>

          {isSuccess ? <Success /> : <DemoForm handleSuccess={handleSuccess} />}
        </section>
      </MainContent>

      <Footer />
    </>
  );
};
