import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  Button,
  emailRegex,
  HttpError,
  InputField,
  SelectField,
  TextArea,
} from 'modules/app';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { interestedIn } from '../consts';
import { useRequestDemo } from '../hooks';

import * as styles from '../styles/DemoForm.styles';

interface Props {
  handleSuccess: VoidFunction;
}

export const DemoForm: React.FC<Props> = ({ handleSuccess }) => {
  const { formatMessage } = useIntl();
  const { error, isLoading, requestDemo, getInterestedInValue } =
    useRequestDemo();

  const values = interestedIn.map((v) => ({
    value: v.value,
    label: formatMessage({ id: v.label }),
  }));

  const interestedInValue = getInterestedInValue(values);

  const methods = useForm<RequestDemoValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      interestedIn: interestedInValue,
      message: '',
    },
  });

  const { handleSubmit } = methods;

  function onSubmit(data: RequestDemoValues) {
    requestDemo(
      {
        ...data,
        interestedIn: data.interestedIn.value,
      },
      handleSuccess,
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div css={styles.form}>
          <div>
            <InputField
              name="firstName"
              value=""
              constraints={{
                required: true,
                maxLength: { value: 50, message: 'shared.errors.max' },
              }}
              label="requestDemo.firstName"
            />
            <InputField
              name="lastName"
              value=""
              constraints={{
                required: true,
                maxLength: { value: 50, message: 'shared.errors.max' },
              }}
              label="requestDemo.lastName"
            />
            <InputField
              name="email"
              value=""
              constraints={{
                required: true,
                pattern: {
                  value: emailRegex,
                  message: 'register.errors.email_invalid',
                },
              }}
              label="requestDemo.email"
            />
            <InputField
              name="company"
              value=""
              constraints={{
                required: true,
                maxLength: { value: 150, message: 'shared.errors.max' },
              }}
              label="requestDemo.company"
              customStyles={styles.company}
            />
          </div>
          <div css={styles.rightSide}>
            <SelectField
              name="interestedIn"
              label={formatMessage({ id: 'requestDemo.interestedIn' })}
              options={values}
              defaultValue={interestedInValue}
            />
            <TextArea
              name="message"
              value=""
              placeholder="requestDemo.messagePlaceholder"
              topLabel="requestDemo.message"
              constraints={{
                required: true,
                maxLength: {
                  value: 1000,
                  message: 'shared.errors.max',
                },
              }}
              customStyles={styles.textArea}
              maxChar="1000"
            />
          </div>
        </div>

        <div css={styles.btn}>
          <Button
            size="largeExtended"
            buttonType="secondary"
            isLoading={isLoading}
          >
            <FormattedMessage id="support.form.submit" />
          </Button>
        </div>
      </form>

      {error && <HttpError error={error} styles={margin.top.xlrg} />}
    </FormProvider>
  );
};
