import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  padding: ${spacing(120)} 0;
`;

export const content = css`
  max-width: 640px;

  > p {
    max-width: 528px;
    margin: ${spacing(40)} 0 ${spacing(80)};
    color: hsl(var(--color-borderDark-5));

    @media ${breakpoints.medium} {
      ${font.size.medium}
    }
  }
`;
