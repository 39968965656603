import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';

export const form = css`
  @media ${breakpoints.medium} {
    ${grid.display}
    ${grid.template.cols2};
    ${grid.gap.column.xxxlrg}
  }
`;

export const rightSide = css`
  @media ${breakpoints.medium} {
    display: flex;
    flex-direction: column;
  }
`;

export const textArea = css`
  @media ${breakpoints.medium} {
    flex: 1;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    textarea {
      height: auto;
      flex: 1;
    }
  }
`;

export const company = css`
  @media ${breakpoints.medium} {
    > div {
      margin-bottom: 0;
    }
  }
`;

export const btn = css`
  margin-top: ${spacing(28)};

  @media ${breakpoints.medium} {
    display: flex;
    justify-content: right;
  }
`;
