import { useLocation } from '@reach/router';
import { HttpError } from 'modules/app/http/client/httpError';
import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';
import { useState } from 'react';

type Value = {
  value: string;
  label: string;
};

export const useRequestDemo = () => {
  const { search } = useLocation();

  const requestHttp = useHttpClient('/api/v1');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<HttpError>();

  async function requestDemo(data: RequestDemo, callback: VoidFunction) {
    setIsLoading(true);

    try {
      await requestHttp.post<RequestDemo>('/sales-contact', data);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  function getInterestedInValue(values: Value[]) {
    const params = new URLSearchParams(search);
    const interestedIn = params.get('interestedIn');

    if (!interestedIn) {
      return values.find((v) => v.value === 'other');
    }

    return values.find((v) => v.value === interestedIn);
  }

  return {
    error,
    isLoading,
    requestDemo,
    getInterestedInValue,
  };
};
